import axios from "axios";

export default async function firebaseURLShortner(code) {
  try {
    const res = await axios.post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_FIREBASE_API_KEY_DEV}`,
      {
        dynamicLinkInfo: {
          domainUriPrefix: "https://meropadhai.page.link",
          link: `https://meropadhai.com/faculties?affiliate=${code}`,
          androidInfo: {
            androidPackageName: "com.meropadhai.clinchtech",
            // androidFallbackLink:
            //   "https://play.google.com/store/apps/details?id=com.meropadhai.clinchtech",
            androidFallbackLink: `https://meropadhai.com/faculties?affiliate=${code}`,
            androidMinPackageVersionCode: "1",
          },
          iosInfo: {
            iosBundleId: "com.clinchtech.meropadhai",
            // iosFallbackLink:
            //   "https://apps.apple.com/us/app/mero-padhai/id6444363644",
            iosFallbackLink: `https://meropadhai.com/faculties?affiliate=${code}`,
            iosCustomScheme: "com.clinchtech.meropadhai",
            // iosIpadFallbackLink:
            //   "https://apps.apple.com/us/app/mero-padhai/id6444363644",
            iosIpadFallbackLink: `https://meropadhai.com/faculties?affiliate=${code}`,
            iosIpadBundleId: "com.clinchtech.meropadhai",
            iosAppStoreId: "6444363644",
          },
          navigationInfo: {
            enableForcedRedirect: false,
          },
          analyticsInfo: {
            googlePlayAnalytics: {
              utmSource: "https://affiliate.meropadhai.com",
              utmMedium: "cpc",
              utmCampaign: "affiliate",
              utmTerm: "affiliate_search",
              utmContent: "jackson",
            },
            itunesConnectAnalytics: {
              at: "jackson",
              ct: "sales",
              mt: "link",
              pt: "affiliate",
            },
          },
          socialMetaTagInfo: {
            socialTitle: "Meropadhai+Padhai+samvab+xa",
            socialDescription:
              "Meropadhai is leading elearning site in Nepal with over 100+ amazing courses.",
            socialImageLink: "https://i.imgur.com/vDH9u4X.png",
          },
        },
        suffix: {
          // option: "UNGUESSABLE",
          option: "SHORT",
        },
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return res?.data;
  } catch (e) {
    console.log(e);
  }
}


// import axios from "axios";

// export default async function firebaseURLShortner(code) {
//   try {
//     const res = await axios.post(
//       `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_FIREBASE_API_KEY_DEV}`,
//       {
//         dynamicLinkInfo: {
//           domainUriPrefix: "https://meropadhai.page.link",
//           link: `https://meropadhai.com/faculties?affiliate=${code}`,
//           androidInfo: {
//             androidPackageName: "com.meropadhai.clinchtech",
//             androidFallbackLink:
//               "https://play.google.com/store/apps/details?id=com.meropadhai.clinchtech",
//             androidMinPackageVersionCode: "1",
//           },
//           iosInfo: {
//             iosBundleId: "com.clinchtech.meropadhai",
//             iosFallbackLink:
//               "https://apps.apple.com/us/app/mero-padhai/id6444363644",
//             iosCustomScheme: "com.clinchtech.meropadhai",
//             iosIpadFallbackLink:
//               "https://apps.apple.com/us/app/mero-padhai/id6444363644",
//             iosIpadBundleId: "com.clinchtech.meropadhai",
//             iosAppStoreId: "6444363644",
//           },
//           navigationInfo: {
//             enableForcedRedirect: false,
//           },
//           analyticsInfo: {
//             googlePlayAnalytics: {
//               utmSource: "https://affiliate.meropadhai.com",
//               utmMedium: "cpc",
//               utmCampaign: "affiliate",
//               utmTerm: "affiliate_search",
//               utmContent: "jackson",
//             },
//             itunesConnectAnalytics: {
//               at: "jackson",
//               ct: "sales",
//               mt: "link",
//               pt: "affiliate",
//             },
//           },
//           socialMetaTagInfo: {
//             socialTitle: "Meropadhai+Padhai+samvab+xa",
//             socialDescription:
//               "Meropadhai is leading elearning site in Nepal with over 100+ amazing courses.",
//             socialImageLink: "https://i.imgur.com/vDH9u4X.png",
//           },
//         },
//         suffix: {
//           // option: "UNGUESSABLE",
//           option: "SHORT",
//         },
//       },
//       { headers: { "Content-Type": "application/json" } }
//     );

//     return res?.data;
//   } catch (e) {
//     console.log(e);
//   }
// }
