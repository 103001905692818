import { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setAffiliate } from "src/redux/slices/affiliateSlice";
import { setLogin } from "src/redux/slices/userSlice";
import postLoginApi from "src/services/auth/postLoginApi";
import { firstErrorToast } from "src/utils/errorsToastify.utils";
import firebaseURLShortner from "../../utils/firebaseURLShortner";

export default function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRememberMe, setIsRememberMe] = useState(false);

  const handleLogin = async (e) => {
    try {
      e.preventDefault();

      if (!email || !password) {
        return toast.error("Please provide all necessary field");
      }
      const res = await postLoginApi({ data: { email, password } });
      if (res) {
        if (res.isAdmin) {
          return toast.error("Please enter login info of affiliate client");
        }

        console.log(res);

        // if (res?.code) {
        //   let link = await firebaseURLShortner(res?.code);
        //   // store this in redux instead of promise
        // }

        dispatch(
          setLogin({
            token: res?.token,
            userData: { email: res?.email, name: res?.name, _id: res?._id },
            isRememberMe,
          })
        );
        dispatch(setAffiliate({ code: res?.code }));
        navigate("/");
      }
    } catch (e) {
      if (e?.response?.data?.validationErrors) {
        return firstErrorToast(e?.response?.data?.validationErrors);
      }

      return toast.error(e?.response?.data?.message);
    }
  };

  return (
    <form action="" onSubmit={handleLogin}>
      <div className="space-y-8">
        <div className="flex flex-col space-y-2">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Enter your email"
            className="input"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="flex flex-col space-y-2">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            placeholder="Enter your password"
            className="input"
            id="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <fieldset className="flex justify-between items-center space-x-2">
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="keepLoggedIn"
              id="keepLoggedIn"
              className="h-[20px] aspect-square"
              value={isRememberMe}
              onChange={(e) => setIsRememberMe(!isRememberMe)}
            />
            <label
              htmlFor="username"
              className="text-sm text-[#6b7280] leading-[10px]"
            >
              Keep me logged in.
            </label>
          </div>

          <Link
            to="/forgot-password"
            className="text-sm text-[#557EE1] underline hidden"
          >
            Forgot Password?
          </Link>
        </fieldset>
      </div>
      <button className="btn-primary mt-[50px]">Login</button>
    </form>
  );
}
