import { toast } from "react-hot-toast";

export function allErrorsToast(errors) {
  Object.entries(errors).forEach((item) => {
    toast.error(item[1]);
    return;
  });
}

export function firstErrorToast(error) {
  return toast.error(Object.entries(error)[0][1][0]);
}
