import ChangePassword from "src/pages/auth/ChangePassword";
import ResetPassword from "src/pages/auth/ResetPassword";
import Login from "src/pages/auth/login";

const authRoutes = [
  {
    id: "register",
    path: "/register",
    component: () => {
      return <div>Hello</div>;
    },
    meta: {
      appLayout: false,
      privateRoute: false,
    },
  },
  {
    id: "forgot-password",
    path: "/forgot-password",
    component: () => {
      return <ResetPassword />;
    },
    meta: {
      appLayout: false,
      privateRoute: false,
    },
  },
  {
    id: "change-password",
    path: "/change-password",
    component: () => {
      return <ChangePassword />;
    },
    meta: {
      appLayout: false,
      privateRoute: false,
    },
  },

  {
    id: "login",
    path: "/*",
    component: Login,
    meta: {
      appLayout: false,
      privateRoute: false,
    },
  },
];

export default authRoutes;
