import { HiRectangleGroup, HiDocumentText, HiUser } from "react-icons/hi2";

const routes = [
  {
    id: "dashboard",
    path: "/",
    icon: <HiRectangleGroup className="mr-3" size={24} />,
    name: "Dashboard",
  },
  {
    id: "statement",
    path: "/statement",
    icon: <HiDocumentText className="mr-3" size={24} />,
    name: "Statement",
  },
  {
    id: "profile",
    path: "/profile",
    icon: <HiUser className="mr-3" size={24} />,
    name: "Profile",
  },
];

export default routes;
