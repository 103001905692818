import { Link } from "react-router-dom";

import { BsFacebook, BsInstagram, BsTwitter, BsLinkedin } from "react-icons/bs";

import logo from "src/assets/images/logo.svg";
import illustration from "src/assets/images/illustration.png";
import slice from "src/assets/images/slice.svg";
import LoginForm from "src/features/auth/login-form";

export default function Login() {
  return (
    <div className="w-screen flex flex-col items-center justify-center p-5">
      <a href="https://www.meropadhai.com" target="_blank">
        <img
          src={logo}
          alt="Mero Padhai Logo"
          className="h-[70px] object-contain"
        />
      </a>

      <div className="flex text-white mt-10">
        <div className="max-w-[1200px] w-full mx-auto flex rounded-[32px] border border-gray-200 shadow-xl overflow-hidden space-x-[50px]">
          <div className="relative w-full bg-primary p-[50px] space-y-4">
            <h1 className="headline-h4 font-bold">Become Affiliate</h1>
            <p className="subheading font-medium">
              Are you passionate about education and want to earn extra income?
            </p>
            <img src={illustration} alt="Login-illustration" />

            <div className="flex space-x-4">
              <Link to="https://www.facebook.com/meropadhai" target="_blank">
                <BsFacebook size={24} />
              </Link>

              <Link to="https://www.instagram.com/meropadhai/" target="_blank">
                <BsInstagram size={24} />
              </Link>

              <Link to="https://twitter.com/MeroPadhai" target="_blank">
                <BsTwitter size={24} />
              </Link>

              <Link
                to="https://www.linkedin.com/company/mero-padhai/"
                target="_blank"
              >
                <BsLinkedin size={24} />
              </Link>
            </div>
            <img
              src={slice}
              alt="Slice"
              className="absolute right-0 bottom-0"
            />
          </div>

          <div className="w-full p-[50px] space-y-4 text-black flex flex-col h-full justify-between">
            <div className="space-y-4">
              <h5 className="headline-h5 font-semibold">Login as Affiliate</h5>
              <p className="body-text text-[#4b5563]">
                Maximize Your Earnings with Affiliate Marketing Login!
              </p>
            </div>

            <LoginForm />

            <p className="text-[#6b7280]">
              Not an Affiliate yet?{" "}
              <Link to={"https://www.meropadhai.com/be-an-affiliate"}>
                <span className="text-primary font-semibold underline underline-offset-2">
                  Register Now
                </span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
