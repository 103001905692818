import { lazy } from "react";

const Home = lazy(() => import("src/pages"));
const Statement = lazy(() => import("src/pages/statement"));
const Profile = lazy(() => import("src/pages/profile"));

const AppRoutes = [
  {
    id: "home",
    path: "/",
    component: Home,
    meta: {
      appLayout: true,
      privateRoute: false,
    },
  },

  {
    id: "statement",
    path: "/statement",
    component: Statement,
    meta: {
      appLayout: true,
      privateRoute: false,
    },
  },

  {
    id: "profile",
    path: "/profile",
    component: Profile,
    meta: {
      appLayout: true,
      privateRoute: false,
    },
  },
];

export default AppRoutes;
