import { createSlice } from "@reduxjs/toolkit";
import { getAffiliateLink, setAffiliateLink } from "src/utils/storage.utils";

const initialState = {
  affiliateLink: getLink() || "",
};

async function getLink() {
  return await getAffiliateLink();
}

const affiliateSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setAffiliate: (state, action) => {
      const res = setAffiliateLink({ ...action.payload });
      state.affiliateLink = res;
    },
  },
});

export const { setAffiliate } = affiliateSlice.actions;
export default affiliateSlice.reducer;
