import { Link } from "react-router-dom";
import { useState } from "react";
import { BiChevronLeft } from "react-icons/bi";

import meropadhaiLogo from "../../assets/images/logo.svg";
import { HiOutlineEye, HiOutlineEyeSlash } from "react-icons/hi2";
import { toast } from "react-hot-toast";

const ChangePassword = () => {
  const [isNewPasswordShown, setIsNewPasswordShown] = useState(false);
  const [isConfirmPasswordShown, setIsConfirmPasswordShown] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  function handleSubmit(e) {
    e.preventDefault();

    if (confirmPassword !== newPassword) {
      toast.error("Passwords don't matched");
    }
  }

  return (
    <div className="flex flex-col items-center h-[100vh] w-full bg-[#F9FAFB]">
      <img src={meropadhaiLogo} className="mt-20 w-[240px]" alt="mero_padhai" />
      <form
        onSubmit={handleSubmit}
        className="drop-shadow-xl  w-[600px] mt-20 p-[50px] border border-gray-200 rounded-xl bg-white"
      >
        <div className="flex flex-col items-center">
          <h5 className="font-semibold text-[24px] font-konnect">
            Verify your Code & Enter Password
          </h5>
          <div className="mt-2 text-sm text-[#4B5563] text-center">
            Check your email at example@mail.com for a Verification code to
            reset your password.
          </div>
        </div>

        <div className="mt-[50px] text-[#1F2937]">
          <div>Verification Code</div>
          <input
            type="text"
            className="mt-2 border rounded-md border-gray-300 w-full p-4 outline-primary"
            placeholder="Enter Verification Code"
            required
          />
        </div>

        <div className="mt-[50px] text-[#1F2937]">
          <div>New Password</div>
          <div className="flex items-center mt-2 border rounded-md border-gray-300 w-full p-4">
            <input
              type={isNewPasswordShown ? "text" : "password"}
              className="w-full outline-none"
              placeholder="Retype new password"
              required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            {isNewPasswordShown ? (
              <HiOutlineEyeSlash
                className="ml-2 h-5 w-5"
                onClick={() => setIsNewPasswordShown((prev) => !prev)}
              />
            ) : (
              <HiOutlineEye
                className="ml-2 h-5 w-5"
                onClick={() => setIsNewPasswordShown((prev) => !prev)}
              />
            )}
          </div>

          <div className="mt-4">Confirm Password</div>
          <div className="flex items-center mt-2 border rounded-md border-gray-300 w-full p-4">
            <input
              type={isConfirmPasswordShown ? "text" : "password"}
              className="w-full outline-none"
              placeholder="Retype new password"
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {isConfirmPasswordShown ? (
              <HiOutlineEyeSlash
                className="ml-2 h-5 w-5"
                onClick={() => setIsConfirmPasswordShown((prev) => !prev)}
              />
            ) : (
              <HiOutlineEye
                className="ml-2 h-5 w-5"
                onClick={() => setIsConfirmPasswordShown((prev) => !prev)}
              />
            )}
          </div>
        </div>

        <div className="mt-[50px] font-konnect">
          <button
            type="submit"
            className="w-full text-white py-3.5 bg-primary rounded-md"
          >
            Change Password
          </button>
          <Link
            to="/forgot-password"
            className="flex justify-center items-center gap-3 mt-[30px] w-full text-[#374151]  rounded-md"
          >
            <BiChevronLeft className="h-5 w-5" />
            Back
          </Link>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
