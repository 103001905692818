import { toast } from "react-hot-toast";
import firebaseURLShortner from "./firebaseURLShortner";

const TOKEN_KEY = "token";
const USER_DATA_KEY = "user";

export function getToken() {
  const localStorageToken = localStorage.getItem(TOKEN_KEY);
  const sessionStorageToken = sessionStorage.getItem(TOKEN_KEY);

  if (localStorageToken) return localStorageToken;
  if (sessionStorageToken) return sessionStorageToken;
  return null;
}

export function setUserLogin(props) {
  const { isRememberMe = false, userData, token } = props;

  if (isRememberMe) {
    localStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
    localStorage.setItem(TOKEN_KEY, token);
    return;
  }

  sessionStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
  sessionStorage.setItem(TOKEN_KEY, token);
}

export function getUserData() {
  const localStorageData = localStorage.getItem(USER_DATA_KEY);
  const sessionStorageData = sessionStorage.getItem(USER_DATA_KEY);

  try {
    if (localStorageData) return JSON.parse(localStorageData);
    if (sessionStorageData) return JSON.parse(sessionStorageData);
  } catch {
    return null;
  }
}

export function isUserLogin() {
  const localStorageToken = localStorage.getItem(TOKEN_KEY);
  const sessionStorageToken = sessionStorage.getItem(TOKEN_KEY);
  const userData = getUserData();

  if ((localStorageToken || sessionStorageToken) && userData) return true;
  return false;
}

export function resetLoginData() {
  localStorage.clear();
  sessionStorage.clear();
}

export function setUserData(userData) {
  if (!userData) return;

  const localStorageUserData = localStorage.getItem(USER_DATA_KEY);
  const sessionStorageUserData = sessionStorage.getItem(USER_DATA_KEY);

  if (localStorageUserData) {
    localStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
    return;
  }
  if (sessionStorageUserData) {
    sessionStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
  }
}

export async function setAffiliateLink({ code }) {
  try {
    const link = await firebaseURLShortner(code);
    localStorage.setItem("affiliateLink", link?.shortLink);
    return link?.shortLink;
  } catch (e) {
    toast.error("Failed to set affiliate code");
    return null;
  }
}

export async function getAffiliateLink(code) {
  try {
    let link = localStorage.getItem("affiliateLink");
    if (!link) {
      const res = await setAffiliateLink(code);
      if (res) {
        link = localStorage.getItem("affiliateLink");
      }
    }
    return link || "";
  } catch (e) {
    toast.error("Failed to get affiliate code");
    return false;
  }
}
