import Sidebar from "./sidebar";

export default function AppLayout({ children }) {
  return (
    <div className="flex bg-[#f3f4f6] w-full min-h-screen">
      <Sidebar />
      <div className="ml-[280px] p-3 md:p-8 w-full">{children}</div>
    </div>
  );
}
