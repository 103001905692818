import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { BiChevronLeft } from "react-icons/bi";

import meropadhaiLogo from "../../assets/images/logo.svg";

const ResetPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    navigate("/change-password");
  }

  return (
    <div className="flex flex-col items-center h-[100vh] w-full bg-[#F9FAFB]">
      <img src={meropadhaiLogo} className="mt-20 w-[240px]" alt="mero_padhai" />
      <form
        onSubmit={handleSubmit}
        className="flex flex-col h-[calc(100vh-230px)] justify-center h-fit w-[600px] mt-20 p-[50px] border border-gray-200 rounded-xl drop-shadow-xl bg-white"
      >
        <div className="flex flex-col items-center">
          <h5 className="font-semibold text-[24px] font-konnect">
            Forgot Password
          </h5>
          <div className="mt-2 text-sm text-[#4B5563]">
            Enter the email to reset password.
          </div>
        </div>

        <div className="mt-[50px] text-[#1F2937]">
          <div>Email</div>
          <input
            type="email"
            className="mt-2 border rounded-md border-gray-300 w-full p-4 outline-primary"
            placeholder="Enter your email"
            required
            value={email}
            onChange={(e) => setEmail(e?.target.value)}
          />
        </div>

        <div className="mt-[50px] font-konnect">
          <button
            type="submit"
            className="w-full text-white py-3.5 bg-primary rounded-md"
          >
            Send Code
          </button>
          <Link
            to="/login"
            className="flex justify-center items-center gap-3 mt-[30px] w-full text-[#374151]  rounded-md"
          >
            <BiChevronLeft className="h-5 w-5" />
            Back
          </Link>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
