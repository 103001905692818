import { Link, useLocation, useNavigate } from "react-router-dom";

import cn from "classnames";
import { BiLogOut } from "react-icons/bi";

import routes from "src/navigation";
import logo from "src/assets/images/logo.png";
import { resetLogin } from "src/redux/slices/userSlice";
import { useDispatch } from "react-redux";

export default function Sidebar() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="fixed top-0 left-0  max-w-[280px] h-full w-full flex-col p-5 bg-white border-r border-r-gray-200 flex scroll-y-auto">
      <a href="https://www.meropadhai.com" target="_blank" className="">
        <img
          src={logo}
          alt="Mero Padhai Logo"
          className="h-[50px] object-contain cursor-pointer "
        />
      </a>

      <div className="mt-[54px] flex flex-col h-full justify-between">
        <ul className="space-y-2 font-konnectMedium">
          {routes.map((route) => (
            <Link
              key={route.name}
              to={route.path}
              className={cn(
                "flex items-center p-3 rounded-lg relative before:h-[24px] before:w-1 before:absolute transition-all duration-300 ease-in-out before:rounded-full before:-left-[1.6px] before:top-1/2 before:-translate-y-1/2",
                { "bg-primary-light text-primary": route.path === pathname }
              )}
            >
              {route.icon}
              <span className="leading-[16px] ">{route.name}</span>
            </Link>
          ))}
        </ul>

        <button
          className="btn-primary p-4 flex gap-4 items-center text-danger bg-danger/20"
          onClick={() => {
            navigate("/login");
            dispatch(resetLogin());
          }}
        >
          <BiLogOut size={24} />
          <span className="leading-3 mt-[2px]">Logout</span>
        </button>
      </div>
    </div>
  );
}
