import { Fragment, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import AppLayout from "src/layouts/app-layout";
import allRoutes from "./routes";
import authRoutes from "./routes/authRoutes";
import { useSelector } from "react-redux";

const MergedLayoutRoute = ({ route, children }) => {
  const AppLayoutWrapper = route?.meta?.appLayout ? AppLayout : Fragment;

  return <AppLayoutWrapper>{children}</AppLayoutWrapper>;
};

export default function Router() {
  const loginStatus = useSelector((state) => {
    return state.user.loginStatus;
  });

  return (
    <BrowserRouter>
      {!loginStatus ? (
        <Routes>
          {authRoutes.map((authRoute, index) => {
            return (
              <Route
                key={authRoute.id}
                path={authRoute.path}
                element={<authRoute.component />}
              />
            );
          })}
        </Routes>
      ) : (
        <Routes>
          {allRoutes.map((route) => (
            <Route
              key={route.id}
              path={route.path}
              element={
                <MergedLayoutRoute route={route}>
                  <Suspense fallback={<p>loading..</p>}>
                    <route.component />
                  </Suspense>
                </MergedLayoutRoute>
              }
            />
          ))}
        </Routes>
      )}
    </BrowserRouter>
  );
}
